<template>
    <div>
        <h4 class="font-weight-bolder">Trends Options</h4>
        <b-card no-body class="mb-0 mt-4">
            <div class="row justify-content-around my-2">
                <div class="col-md-2">
                    <label>View Factor (Not a Multiple of 5)</label>
                    <b-form-input class="mx-1" type="text" placeholder="View Factor" v-model="form.view_factor" />
                </div>
                <div class="col-md-2">
                    <label>Number of Trends (10 to 100)</label>
                    <b-form-input class="mx-1" type="text" placeholder="Number of Trends" v-model="form.n_trends" />
                </div>
                <div class="col-md-2">
                    <label>Number of Past Days (1 to 60)</label>
                    <b-form-input class="mx-1" type="text" placeholder="Past Days" v-model="form.n_days" />
                </div>
                <div class="col-md-2">
                    <label>Max Manual Days</label>
                    <b-form-input class="mx-1" type="text" placeholder="Max Manual Days" v-model="form.max_manual_days" />
                </div>
            </div>
            <div class="row justify-content-center mb-2">
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" class="mt-2 ml-2 btn-icon" @click="onSubmit()"> Submit </b-button>
            </div>
        </b-card>
    </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import flatPickr from "vue-flatpickr-component";
import { BFormSpinbutton, BInputGroup, BFormDatepicker, BFormCheckbox, BFormSelect, BTab, BTabs, BCard, BAlert, BFormGroup, BForm, BRow, BCol, BFormInput, BMedia, BFormFile, BImg, BFormTextarea, BCardText, BButtonGroup, BButton, BMediaBody, BMediaAside, BFormInvalidFeedback, BFormValidFeedback } from "bootstrap-vue";
import Cleave from "vue-cleave-component";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required } from "@validations";
import { computed, watch, ref, onMounted, inject } from "@vue/composition-api";
import router from "@/router";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { makeid } from "@/utils/math";
import useTrendingList from "./useTrendingList";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import ArtistRepository from "@/abstraction/repository/artistRepository";
import MusicRepository from "@/abstraction/repository/musicRepository";
import VideoRepository from "@/abstraction/repository/videoRepository";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
const RANDOM_TOKEN = makeid(50);

export default {
    components: {
        AppCollapse,
        AppCollapseItem,
        BTab,
        Cleave,
        BFormInput,
        BInputGroup,
        BFormCheckbox,
        BFormInvalidFeedback,
        BFormValidFeedback,
        BFormGroup,
        BForm,
        BFormTextarea,
        BFormFile,
        BImg,
        BMedia,
        BTabs,
        BCard,
        BAlert,
        BRow,
        BCol,
        vSelect,
        BFormSelect,
        BButton,
        BCardText,
        BMediaAside,
        BMediaBody,
        BFormSpinbutton,
        apexchart: VueApexCharts,
        ToastificationContent,
        // Form Validation
        ValidationProvider,
        ValidationObserver,
        flatPickr,
        BFormDatepicker,
        BButtonGroup,
    },
    directives: {
        Ripple,
    },
    setup(props, context) {
        //data
        const { fetchCoefficient, updateCoefficient } = useTrendingList();
        const isLoading = ref(false);
        const form = ref({
            view_factor: undefined,
            n_trends: undefined,
            n_days: undefined,
            max_manual_days: undefined,
        });
        const validation = ref(true);
        const onSubmit = async () => {
            let data = form.value;
            updateCoefficient(data).then((res) => {
                if (res == false) {
                    context.root.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Error",
                            icon: "BellIcon",
                            text: "Sorry! There is An Error...",
                            variant: "danger",
                        },
                    });
                } else {
                    context.root.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Updated",
                            icon: "BellIcon",
                            text: "The Coefficients Updated Successfully👋",
                            variant: "primary",
                        },
                    });
                }
            });
        };
        onMounted(() => {
            fetchCoefficient().then((res) => {
                form.value = res;
            });
        });
        return {
            onSubmit,
            required,
            validation,
            isLoading,
            form,
        };
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
